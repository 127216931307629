import "../../device-info.js";
import {waitForNotElement} from "../forms/form.js";
import {submitZipForm} from "../forms/zip-code-form.js";

/**
 * Posts a zipcode form to ad-listing page.
 *
 * @callback adListingFunction
 * @param {jQuery} zipcodeForm the zip code form
 */
const postZipcodeFormToAdListing = function (zipcodeForm) {
    if (!window.location.pathname.includes("mobile") && $("form input[name=deviceInfo]").first().val().includes("desktop")) {
        const newTab = window.open("/please-wait", "popover");
        if (newTab) {
            zipcodeForm.attr("target", "popover");

            let url = "/ad-listing.php?zipcode=" + zipcodeForm.find("[name=zipcode]").val();
            url += zipcodeForm.find("[name=utm_campaign]").val() ? "&utm_campaign=" + zipcodeForm.find("[name=utm_campaign]").val() : "";
            url += zipcodeForm.find("[name=utm_medium]").val() ? "&utm_medium=" + zipcodeForm.find("[name=utm_medium]").val() : "";
            url += zipcodeForm.find("[name=utm_source]").val() ? "&utm_source=" + zipcodeForm.find("[name=utm_source]").val() : "";

            const formPopUnder = $("<form>", {
                id: zipcodeForm.attr("id") + "-ad-listing",
                method: "POST",
                action: url,
            });
            formPopUnder.insertAfter(zipcodeForm).trigger("submit");
        }
    }
};

/**
 * Add validation to zip code form before submit.
 *
 * @param {jQuery} zipcodeForm the zip code form
 * @param {function} zipcodeFormCallbackFunction submits the zip form
 */
export function addZipcodeFormSubmitListener(zipcodeForm, zipcodeFormCallbackFunction) {
    let zipcodeFormButton = zipcodeForm.find("button[type=submit]");
    zipcodeFormButton.removeAttr("disabled");
    zipcodeForm.on("submit", function () {
        const zipcodeFormButton = zipcodeForm.find("[type=submit]");
        const zipcodeVerifying = "#" + zipcodeForm.attr("id") + " .zipcode.verifying";
        if ($(zipcodeVerifying).length === 0) {
            submitZipForm(zipcodeForm, zipcodeFormButton, zipcodeFormCallbackFunction);
        } else {
            waitForNotElement(zipcodeVerifying, () => {
                submitZipForm(zipcodeForm, zipcodeFormButton, zipcodeFormCallbackFunction);
            });
        }
        zipcodeFormButton.trigger("reset");

        return false;
    });
}

let zipcodeForms = $("form[data-type=zipcode-form]");
zipcodeForms.each(function (counter, zipcodeForm) {
    addZipcodeFormSubmitListener($(zipcodeForm), postZipcodeFormToAdListing);
});
